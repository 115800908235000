<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="全球合作伙伴 - 州详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveTypeModel">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="state_model"
          :rules="rules"
          ref="state_model"
          label-width="150px"
        >
          <el-form-item label="名称:" prop="name">
            <el-input v-model="state_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="state_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 名称是否显示在前端 -->
          <el-form-item label="名称是否显示在前端:">
            <el-switch
              v-model="state_model.show_name_on_frontend"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </el-form-item>
          <el-form-item label="排序:">
            <el-input-number v-model="state_model.number" :min="0" label="排序"></el-input-number>
          </el-form-item>
          <el-form-item style="text-align: left" label="PC图标:">
            <el-input v-model="state_model.pc_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="filterImageLink(state_model.pc_image_url, $common.K_QINIU_IMAGE_SMALL_END_200)"
                  :preview-src-list="[state_model.pc_image_url]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="text-align: left" label="WAP图标:">
            <el-input v-model="state_model.wap_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  fit="contain"
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="filterImageLink(state_model.wap_image_url, $common.K_QINIU_IMAGE_SMALL_END_200)"
                  :preview-src-list="[state_model.wap_image_url]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <CopyPaste
            :button_status="this.state_id !== undefined && this.state_id.length > 0"
            @copyInfo="copyInfo(state_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="globalPartnersStateFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../../components/CopyPaste.vue";
import { filterImageLink } from "@/utils";

export default {
  name: "GlobalPartnersStateDetailView",
  components: {
    CopyPaste,
    FileChooseDrawerModule
  },
  data() {
    return {
      filterImageLink,
      loading: false,
      state_id: "",
      state_model: {
        id: "",
        name: "",
        alias: "",
        number: "",
        pc_image_url: "",
        wap_image_url: "",
        status: 2,
        show_name_on_frontend: true
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        product_id: [
          { required: true, message: "请选择产品", trigger: "change" }
        ]
      }
    };
  },
  created() {
    this.state_id = this.$route.query.id;
    if (this.state_id !== undefined && this.state_id.length > 0) {
      this.getStateModel();
    }
  },
  methods: {
    getStateModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.state_id
      };
      _me.$common
        .httpPost("/api/brand/global_partners/admin/state/find/id", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.state_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    saveTypeModel() {
      if (this.state_id !== undefined && this.state_id.length > 0) {
        this.updateTypeModel();
      } else {
        this.newTypeModel();
      }
    },
    updateTypeModel() {
      let _me = this;
      let params = {
        id: _me.state_model.id,
        name: _me.state_model.name,
        alias: _me.state_model.alias,
        number: _me.state_model.number,
        pc_image_url: _me.state_model.pc_image_url,
        wap_image_url: _me.state_model.wap_image_url,
        status: _me.state_model.status,
        show_name_on_frontend: _me.state_model.show_name_on_frontend
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/state/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newTypeModel() {
      let _me = this;
      let params = {
        name: _me.state_model.name,
        alias: _me.state_model.alias,
        number: _me.state_model.number,
        pc_image_url: _me.state_model.pc_image_url,
        wap_image_url: _me.state_model.wap_image_url,
        status: _me.state_model.status,
        show_name_on_frontend: _me.state_model.show_name_on_frontend
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/state/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.globalPartnersStateFileChooseDrawerModule.showChooseDrawer(
        function(chooseFileList) {
          console.log("接收到选择的文件:", chooseFileList);
          if (chooseFileList.length > 0) {
            switch (imageType) {
              case "pc_image_url":
                _me.state_model.pc_image_url = chooseFileList[0].file_url;
                break;
              case "wap_image_url":
                _me.state_model.wap_image_url = chooseFileList[0].file_url;
                break;
              default:
                break;
            }
          }
        }
      );
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      this.$common.removeGlobalStorage("GlobalPartnerStateList");
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.state_model = model;
      this.state_model.id = this.state_id;
    }
  }
};
</script>

<style scoped></style>
