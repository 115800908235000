<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="全球合作伙伴 - 地区详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveCategoryModel">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="region_model"
          :rules="rules"
          ref="region_model"
          label-width="150px"
        >
          <el-form-item label="所属大州:">
            <el-select v-model="region_model.state_id" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in state_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称:" prop="name">
            <el-input v-model="region_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="别名:">
            <el-input v-model="region_model.alias" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="region_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 名称是否显示在前端 -->
          <el-form-item label="名称是否显示在前端:">
            <el-switch
              v-model="region_model.show_name_on_frontend"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </el-form-item>
          <el-form-item label="权重:">
            <el-input-number v-model="region_model.number" :min="0" label="权重"></el-input-number>
          </el-form-item>
          <el-form-item style="text-align: left" label="PC端图标:">
            <el-input v-model="region_model.pc_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="
                  filterImageLink(region_model.pc_image_url, $common.K_QINIU_IMAGE_SMALL_END_100_PX)
                  "
                  :preview-src-list="[region_model.pc_image_url]"
                  lazy
                  fit="contain"
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="text-align: left" label="WAP端图标:">
            <el-input v-model="region_model.wap_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="
                  filterImageLink(region_model.wap_image_url, $common.K_QINIU_IMAGE_SMALL_END_100_PX)
                  "
                  :preview-src-list="[region_model.wap_image_url]"
                  lazy
                  fit="contain"
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <CopyPaste
            :button_status="
              this.region_model_id !== undefined && this.region_model_id.length > 0
            "
            @copyInfo="copyInfo(region_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="globalParentsRegionFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../../components/CopyPaste.vue";
import { filterImageLink } from "@/utils";

export default {
  name: "GlobalPartnersRegionDetailView",
  components: {
    CopyPaste,
    FileChooseDrawerModule
  },
  data() {
    return {
      filterImageLink,
      loading: false,
      state_list: [],
      region_model_id: "",
      region_model: {
        id: "",
        state_id: "",
        name: "",
        number: 0,
        alias: "",
        pc_image_url: "",
        wap_image_url: "",
        status: 2,
        show_name_on_frontend: true
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }]
      }
    };
  },
  created() {
    this.getStateList();
    this.region_model_id = this.$route.query.id;
    if (this.region_model_id !== undefined && this.region_model_id.length > 0) {
      this.getRegionModel();
    }
  },
  methods: {
    getStateList() {
      let _me = this;
      let key = "GlobalPartnerStateList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.state_list = val;
        return;
      }

      let params = {
        status: 1,
        page_size: -1,
        page_number: 1,
        sort_fields: "number"
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/state/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.state_list = res.data.array === undefined ? [] : res.data.array;

            _me.$common.getGlobalStorage(key, _me.state_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getRegionModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.region_model_id
      };
      _me.$common
        .httpPost("/api/brand/global_partners/admin/region/find/id", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.region_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    saveCategoryModel() {
      if (
        this.region_model_id !== undefined &&
        this.region_model_id.length > 0
      ) {
        this.updateCategoryModel();
      } else {
        this.newCategoryModel();
      }
    },
    updateCategoryModel() {
      let _me = this;
      let params = {
        id: _me.region_model.id,
        state_id: _me.region_model.state_id,
        name: _me.region_model.name,
        alias: _me.region_model.alias,
        number: _me.region_model.number,
        pc_image_url: _me.region_model.pc_image_url,
        wap_image_url: _me.region_model.wap_image_url,
        status: _me.region_model.status,
        show_name_on_frontend: _me.region_model.show_name_on_frontend
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/region/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newCategoryModel() {
      let _me = this;
      let params = {
        state_id: _me.region_model.state_id,
        name: _me.region_model.name,
        alias: _me.region_model.alias,
        number: _me.region_model.number,
        pc_image_url: _me.region_model.pc_image_url,
        wap_image_url: _me.region_model.wap_image_url,
        status: _me.region_model.status,
        show_name_on_frontend: _me.region_model.show_name_on_frontend
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/brand/global_partners/admin/region/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.globalParentsRegionFileChooseDrawerModule.showChooseDrawer(
        function(chooseFileList) {
          console.log("接收到选择的文件:", chooseFileList);
          if (chooseFileList.length > 0) {
            switch (imageType) {
              case "pc_image_url":
                _me.region_model.pc_image_url = chooseFileList[0].file_url;
                break;
              case "wap_image_url":
                _me.region_model.wap_image_url = chooseFileList[0].file_url;
                break;
              default:
                break;
            }
          }
        }
      );
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.state_list.forEach(item => {
        if (item.id == str.state_id) {
          str.state_name = item.name;
        }
      });
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.region_model = model;
      this.region_model.id = this.region_model_id;
      this.state_list.forEach(item => {
        if (item.name == model.state_name) {
          this.region_model.state_id = item.id;
        }
      });
    }
  }
};
</script>

<style scoped></style>
